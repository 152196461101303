<template>
<div class='d-none d-lg-block'>
    <nav class="navbar fixed-bottom navbar-light bg-light footer">
      <div>
        <span>&copy; 2021 track/49 v2.0.1. </span>
      </div>
      <div>
        <nav class="nav">
          <a href="/" class="nav-link">Get Help</a>
        </nav>
      </div>
    </nav>
</div>
</template>
